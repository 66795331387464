import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { WalletsProfitGraph } from '@entities/wallet';
import { ContainerRow } from '@components/styled';
import BackButton from '@shared/ui/navigation/BackButton';
import PageFrameContainer from '@shared/ui/layout/PageFrameContainer';
import { ExchangeWallet } from '@features/exchange-wallet';
import { useAppDispatch, useAppSelector } from '@store/Hooks';
import { getWalletsWithExchanges } from '@store/slices/exchange/thunks';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import WALLETS_PROFIT_DATA from './mocks';
import prepareWalletsData from './utils';
const HeaderContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.l,
    justifyContent: 'space-between',
    maxHeight: '340px',
}));
const OverviewWalletDetails = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { navigate, routes } = useAppNavigation();
    const { walletId } = useParams();
    const { exchangesList, walletsOverviewList, } = useAppSelector(store => store.exchange);
    useEffect(() => {
        if (walletsOverviewList.list === null && exchangesList.list === null) {
            dispatch(getWalletsWithExchanges());
        }
    }, [
        exchangesList,
        walletsOverviewList,
        dispatch,
    ]);
    const wallet = useMemo(() => {
        const wallets = walletsOverviewList.list || [];
        return wallets.find((w) => w.id === walletId);
    }, [
        walletId,
        walletsOverviewList,
    ]);
    const { isLoading } = walletsOverviewList;
    const walletsData = useMemo(() => prepareWalletsData(WALLETS_PROFIT_DATA), []);
    const navigateToOverview = () => {
        navigate(routes.HOME_OVERVIEW);
    };
    return (_jsx(PageFrameContainer, { header: !!wallet && (_jsxs(_Fragment, { children: [_jsx(BackButton, { text: t('pages.overview.overview', { ns: 'common' }), onClick: navigateToOverview }), _jsxs(HeaderContainer, { children: [_jsx(ExchangeWallet, { variant: 'embedded', wallet: wallet, portfolios: [] }), _jsx(WalletsProfitGraph, { walletsData: walletsData })] })] })), gap: theme.spacing_sizes.l, headerStyles: {
            padding: '42px 0 30px 0',
        }, bottom: (_jsx("p", { children: "Wallet Details will be here" })), isLoading: isLoading, error: undefined }));
};
export default OverviewWalletDetails;
